/* eslint-disable react/prop-types,react/display-name */
import { keyframes } from '@emotion/core';
import React, { Fragment, useState } from 'react';
import { useIntlContext } from 'gatsby-theme-fast-ai-sidebar/src/intl';
import { graphql } from 'gatsby';
import {
	Box,
	Col,
	Heading,
	Row,
	Text,
	ThemeProvider,
} from '@fast-ai/ui-components';
import { Paragraph } from 'gatsby-theme-fast-ai-sidebar/src/mdxComponents';
import DownloadIcon from '@fast-ai/dashboard-icons/src/Download';
import { useForm } from 'react-form';

import { DefaultLayout } from '../layout';
import { TextField } from '../forms';
import { ButtonWithIcon, CompaniesBrands, Section, Seo } from '../ui';
import { sendEmail } from '../api';
import { RichFormattedMessage, useTranslateMessage } from '../intl';
import m from '../intl/messages/pageDownloadEbook';
import { createContrastTheme } from '../contrastTheme';
import ScheduleMeetingSection from '../ui/ScheduleMeetingSection';

const ebookLink = 'zoe-case-study-2024.pdf';
const ebookLinkEn = 'zoe-case-study-2024-EN.pdf';

function downloadURI(uri, name) {
	const link = document.createElement('a');
	link.download = name;
	link.href = uri;
	link.click();
}
const downloadPdf = lang => {
	const fileName = lang === 'en' ? ebookLinkEn : ebookLink;
	downloadURI(`/${fileName}`, fileName);
};

const Card = ({ sx, ...rest }) => (
	<Box as="article" sx={{ p: 4, ...sx }} {...rest} />
);

const fakeProgressBarGrowAnimation = keyframes`
  0% { width: 0; }
  25% { width:100%; }
  100% { width: normal; }
`;
const FakeProgressBar = () => (
	<Box
		sx={{
			width: '100%',
			maxWidth: '350px',
			height: '1.66em',
			backgroundColor: 'rgba(8,102,220,.2)',
			overflow: 'hidden',
			borderRadius: '5px',
			margin: '0 auto',
		}}
	>
		<Box
			sx={{
				height: '1.66em',
				float: 'left',
				width: '100%',
				backgroundColor: 'primary',
				animationDuration: '8s',
				animationTimingFunction: 'fade-in',
				animationName: fakeProgressBarGrowAnimation,
			}}
		/>
	</Box>
);
const defaultValues = {
	name: '',
	email: '',
};

const validateEmail = email =>
	String(email)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
const Status = {
	EMAIL_SUBMITTED: 'EMAIL_SUBMITTED',
	IN_PROGRESS_OF_FILL_IN: 'IN_PROGRESS_OF_FILL_IN',
};
const FormSection = ({ sx, setStatus }) => {
	const [errorMessage, setErrorMessage] = useState('');

	const { language: currentLanguage } = useIntlContext();
	const translate = useTranslateMessage();
	const {
		Form,
		meta: { isSubmitting, canSubmit },
		reset,
	} = useForm({
		defaultValues,
		onSubmit: async values => {
			try {
				setErrorMessage('');
				values.message = 'User downloaded the e-book.';
				await sendEmail(values);

				setStatus(Status.EMAIL_SUBMITTED);
				setTimeout(() => {
					downloadPdf(currentLanguage);
				}, 2000);
				reset();
			} catch (error) {
				setErrorMessage(translate(m.formInvalidMailOrPhone));
			}
		},
	});
	return (
		<Form>
			<Card
				sx={{
					backgroundColor: 'backgroundPrimary',
					borderStyle: 'solid',
					borderWidth: '4px',
					borderColor: 'primary',
					borderRadius: 'basic',
					...sx,
				}}
			>
				<Heading variant="subHeading2">
					<RichFormattedMessage {...m.formHeading} />
				</Heading>

				<Box sx={{ textAlign: 'left', mb: 2 }}>
					{errorMessage && (
						<Paragraph>
							<Text as="span" sx={{ color: 'danger' }}>
								{errorMessage}
							</Text>
						</Paragraph>
					)}
					<Text>
						<RichFormattedMessage {...m.howToFillForm} />
					</Text>
					<TextField
						label={<RichFormattedMessage {...m.nameSurnameLabel} />}
						field="name"
						validate={x => (!x ? translate(m.requiredName) : false)}
					/>
					<TextField
						label={<RichFormattedMessage {...m.emailLabel} />}
						field="email"
						validate={x =>
							!x || !validateEmail(x) ? translate(m.invalidEmail) : false
						}
					/>
				</Box>

				<Box>
					<ButtonWithIcon
						disabled={!canSubmit || isSubmitting}
						sx={{ margin: 'auto' }}
						label={<RichFormattedMessage {...m.downloadCaseStudyEbook} />}
						icon={<DownloadIcon />}
					/>
				</Box>
			</Card>
		</Form>
	);
};

const DownloadEbookPage = props => {
	const translate = useTranslateMessage();
	const [status, setStatus] = useState(Status.IN_PROGRESS_OF_FILL_IN);
	// const [status, setStatus] = useState(Status.EMAIL_SUBMITTED);

	let heading;
	let content;
	let afterSection;

	if (status === Status.EMAIL_SUBMITTED) {
		heading = (
			<Fragment>
				<Heading sx={{ mb: [0, 0, 0] }}>
					<RichFormattedMessage {...m.emailSubmittedHeading} />
				</Heading>
				<Heading as="h2" variant="subHeading1" sx={{ mb: [1, 3, 4] }}>
					<RichFormattedMessage {...m.emailSubmittedSubheading} />
				</Heading>
			</Fragment>
		);
		content = <FakeProgressBar />;
		afterSection = (
			<Fragment>
				<ThemeProvider theme={createContrastTheme}>
					<Col
						span={[12, 12, 5]}
						sx={{
							backgroundColor: 'background',
							borderRadius: 'basic',
							textAlign: 'center',
							mb: 4,
							mx: 'auto',
						}}
					>
						<ScheduleMeetingSection />;
					</Col>
				</ThemeProvider>

				<Section variant="stripesSecondary">
					<CompaniesBrands hideActions location={props.location} />
				</Section>
			</Fragment>
		);
	} else {
		heading = (
			<Fragment>
				<Heading sx={{ mb: [0, 0, 0] }}>
					<RichFormattedMessage {...m.heading} />
				</Heading>
				<Heading variant="subHeading1" sx={{ mb: [1, 3, 4] }}>
					<RichFormattedMessage {...m.perex} />
				</Heading>
			</Fragment>
		);
		content = (
			<Row sx={{ flexWrap: 'wrap' }}>
				<Col
					span={[12, 12, 8]}
					sx={{
						margin: '0 auto',
					}}
				>
					<FormSection {...props} status={status} setStatus={setStatus} />;
				</Col>
			</Row>
		);
		afterSection = (
			<Section>
				<CompaniesBrands hideActions location={props.location} />
			</Section>
		);
	}
	return (
		<DefaultLayout {...props}>
			<Seo title={translate(m.title)} />
			<Section>
				<Row sx={{ flexWrap: 'wrap' }}>
					<Col
						span={[12, 12, 8]}
						sx={{
							margin: '0 auto',
						}}
					>
						{heading}
					</Col>
				</Row>
				{content && (
					<Row sx={{ flexWrap: 'wrap' }}>
						<Col
							span={[12, 12, 8]}
							sx={{
								margin: '0 auto',
							}}
						>
							{content}
						</Col>
					</Row>
				)}
			</Section>
			{afterSection}
		</DefaultLayout>
	);
};

export const query = graphql`
	{
		michal: file(relativePath: { eq: "michal-krnak.jpg" }) {
			childImageSharp {
				gatsbyImageData(width: 600, quality: 70, layout: CONSTRAINED)
			}
		}
	}
`;

export default DownloadEbookPage;
