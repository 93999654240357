import { defineMessages } from 'gatsby-theme-fast-ai-sidebar';

export default defineMessages({
	title: {
		id: 'page.downloadEbook.title',
		defaultMessage: 'Download the E-book',
	},
	formInvalidSubmit: {
		id: 'page.downloadEbook.invalidSubmit',
		defaultMessage: 'Invalid submit. Please try it again.',
	},
	formMissingName: {
		id: 'page.downloadEbook.missingName',
		defaultMessage: 'Name is required.',
	},
	formInvalidMail: {
		id: 'page.downloadEbook.invalidMail',
		defaultMessage: 'Email is required.',
	},
	emailSubmittedHeading: {
		id: 'page.downloadEbook.emailSubmittedHeading',
		defaultMessage: 'Thank you!',
	},
	emailSubmittedSubheading: {
		id: 'page.downloadEbook.emailSubmittedSubheading',
		defaultMessage: 'The e-book will download soon.',
	},
	howToFillForm: {
		id: 'page.downloadEbook.howToFillForm',
		defaultMessage:
			'Please fill in your name and email so we can provide the e-book to you.',
	},
	nameSurnameLabel: {
		id: 'page.downloadEbook.nameSurnameLabel',
		defaultMessage: 'Name and surname',
	},
	requiredName: {
		id: 'page.downloadEbook.requiredName',
		defaultMessage: 'Name is required.',
	},
	emailLabel: {
		id: 'page.downloadEbook.emailLabel',
		defaultMessage: 'Email',
	},
	invalidEmail: {
		id: 'page.downloadEbook.invalidEmail',
		defaultMessage: 'Invalid email.',
	},
	heading: {
		id: 'page.downloadEbook.heading',
		defaultMessage: '<strong>Elevate</strong> Your Business{br}',
	},
	perex: {
		id: 'page.downloadEbook.perex',
		defaultMessage:
			'Download the Zoe.ai E-book {br}for <strong>Inspiring</strong> Client Stories',
	},
	formHeading: {
		id: 'page.downloadEbook.formHeading',
		defaultMessage: 'Contact information',
	},
	downloadCaseStudyEbook: {
		id: 'page.downloadEbook.downloadCaseStudyEbook',
		defaultMessage: 'Download case study',
	},
});
